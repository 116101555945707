/* Product View, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="product-view" class="entity-view">
      <v-card-title>View Product</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>

        <Can I="update" on="Administration">
          <v-btn color="primary" class="me-3" :to="{ name: 'product-edit', params: { id: entity.Id } }">
            <v-icon size="18" class="me-1">{{ icons.mdiPencilOutline }}</v-icon>
            <span>Edit</span>
          </v-btn>
        </Can>

        </v-col>
        <v-col class="text-right">

          <Can I="create" on="Administration">
            <v-btn color="secondary" class="me-3" :to="{ name: 'product-create' }" >
              <v-icon size="18" class="me-1">{{ icons.mdiPlus }}</v-icon>
              <span>Create Product</span>
            </v-btn>
          </Can>

          <v-btn color="secondary" class="me-3" :to="{ name: 'product-list'}">
            <v-icon size="18" class="me-1">{{ icons.mdiViewList }}</v-icon>
            <span>Back</span>
          </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-simple-table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Code</td>
        <td>{{ entity.Code }}</td>
      </tr>

      <tr>
        <td>Name</td>
        <td>{{ entity.Name }}</td>
      </tr>

      <tr>
        <td>ProductCategory Name</td>
        <td>{{ entity.ProductCategoryId_Name }}</td>
      </tr>

      <tr>
        <td>Length</td>
        <td>{{ entity.Length }}</td>
      </tr>

      <tr>
        <td>Width</td>
        <td>{{ entity.Width }}</td>
      </tr>

      <tr>
        <td>Height</td>
        <td>{{ entity.Height }}</td>
      </tr>

      <tr>
        <td>Price</td>
        <td>{{ Display.Money(entity.Price) }}</td>
      </tr>

      <tr>
        <td>Example</td>
        <td>{{ entity.Example }}</td>
      </tr>

      <tr>
        <td>TTLMD Code</td>
        <td>{{ entity.TTLMDProductCode }}</td>
      </tr>

      <tr>
        <td>Volume Tolerance</td>
        <td>{{ entity.VolumeTolerance }}</td>
      </tr>

      <tr>
        <td>Dimension Tolerance</td>
        <td>{{ entity.DimensionTolerance }}</td>
      </tr>

      <tr>
        <td>Order</td>
        <td>{{ entity.SortOrder }}</td>
      </tr>

      <tr>
        <td>Enabled</td>
        <td><v-icon size="18" :color="booleanIcons(entity.Enabled).variant">{{ booleanIcons(entity.Enabled).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Public</td>
        <td><v-icon size="18" :color="booleanIcons(entity.Public).variant">{{ booleanIcons(entity.Public).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Guesstimate</td>
        <td><v-icon size="18" :color="booleanIcons(entity.Guesstimate).variant">{{ booleanIcons(entity.Guesstimate).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Rotatable</td>
        <td><v-icon size="18" :color="booleanIcons(entity.Rotatable).variant">{{ booleanIcons(entity.Rotatable).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Created</td>
        <td>{{ Display.DateTime(entity.Created) }}</td>
      </tr>

      <tr>
        <td>Updated</td>
        <td>{{ Display.DateTime(entity.Updated) }}</td>
      </tr>

        </tbody>
      </v-simple-table>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import { Can } from '@casl/vue'

import {
  mdiPencilOutline,
  mdiPlus,
  mdiViewList,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiClose
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'

export default {
  components: {
    Can
  },
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => store.state.app.Product);
    const loading = ref(false);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchProduct', {
          id: id
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'product-list'});
        })
    }

    // fetch the user information when params change
    watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);


    const booleanIcons = status => {
      if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
      return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
    }


    return {
      loading,
      entity,
      Display,
      booleanIcons,
      icons: {
        mdiPencilOutline,
        mdiPlus,
        mdiViewList,
        mdiClose
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-view::v-deep .v-data-table th:last-child {
  width: 66%;
}
</style>

